import { useEffect } from "react";
import { getTrackingData, isHTMLElement } from "../utils";
import tracker from "../tracker";
import Logger from "@cappelendamm/logger";

export const useTrackIntersectionObserver = <Event extends Record<string, any>>(
  pathname: string,
  event?: Event,
  selector = '[data-track-viewport="true"]',
  enabled = true,
) => {
  useEffect(() => {
    const observers: IntersectionObserver[] = [];
    try {
      document.querySelectorAll(selector).forEach((item) => {
        const observer = new IntersectionObserver(
          (entry) => {
            if (
              entry.some(({ isIntersecting }) => isIntersecting) &&
              isHTMLElement(item) &&
              enabled
            )
              tracker.trackEvent({
                eventName: "in_viewport",
                pathname,
                ...getTrackingData(item),
                ...event,
              });
          },
          {
            threshold: 0.9,
          },
        );
        observer.observe(item);
        observers.push(observer);
      });
    } catch (e) {
      Logger.error(e);
    }
    return () => {
      observers.forEach((observer) => observer.disconnect());
    };
  }, [pathname, selector, event, enabled]);
};
