import { NextRequest, NextResponse } from "next/server";
import { filterPath } from "./filter-path";
import Logger from "@cappelendamm/logger";

import {
  EXPERIMENT_GROUP_COOKIE_NAME,
  generateExperimentGroup,
} from "@/experiment";

export const getUniverseFromHost = (
  host: string,
  universeTags?: string[],
): string | null => {
  const matcher = new RegExp(`(?<tag>${universeTags?.join("|")})`, "gi");
  const matches = host.match(matcher);
  if (!matches) return null;
  const universe = `${matches[0]}`.toLowerCase();
  return universe;
};

export const COOKIE_UNIVERSE = "universe";

function getUniverseCookie({ cookies }: Pick<NextRequest, "cookies">) {
  return cookies.get(COOKIE_UNIVERSE)?.value ?? null;
}

function getUniverse(request: NextRequest, universeTags: string[]) {
  const universeTag =
    getUniverseFromHost(request.nextUrl.host, universeTags) ??
    getUniverseCookie(request);
  return universeTag;
}

const getExperimentGroup = ({
  cookies,
}: Pick<NextRequest, "cookies">): string => {
  const experimentGroup =
    cookies.get(EXPERIMENT_GROUP_COOKIE_NAME)?.value ??
    generateExperimentGroup();
  return experimentGroup;
};

const getQueryParams = (searchParams: URLSearchParams) => {
  if (searchParams.toString().length > 0) {
    return `?${searchParams}`;
  }
  return "";
};

export function resolveSitePage(request: NextRequest, universeTags: string[]) {
  const {
    cookies,
    nextUrl: { pathname, searchParams },
  } = request;

  const experimentGroup = getExperimentGroup({ cookies });
  const universeTag = getUniverse(request, universeTags);

  if (!universeTag) {
    Logger.error(`Unable to resolve universe. Request ${request.url}`);
    return NextResponse.next();
  }

  const page = filterPath({ experimentGroup, pathname, universeTag });
  const queryParams = getQueryParams(searchParams);
  const url = `/${experimentGroup}/${universeTag.replace("/", "")}${page}${queryParams}`;

  const response = NextResponse.rewrite(new URL(url, request.url));
  response.cookies.set(EXPERIMENT_GROUP_COOKIE_NAME, experimentGroup);
  response.cookies.set(COOKIE_UNIVERSE, universeTag.replace("/", ""));

  return response;
}

export function resolveCMSUrl(request: NextRequest, universeTags: string[]) {
  const { pathname, host } = request.nextUrl;
  const universeTag = getUniverse(request, universeTags);

  const baseUrl =
    process.env.NEXT_PUBLIC_SANITY_URL ?? "https://cms-cappelendamm.vercel.app";
  const url = `${baseUrl}${pathname.replace("/cms-studio", `/${universeTag ?? ""}`)}`;

  Logger.log(`Redirected: ${request.url} to: ${url} - Host: ${host}`);

  return NextResponse.redirect(url);
}
