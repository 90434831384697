"use client";

import React, { FC, useEffect } from "react";
import tracker from "../tracker";
import { getTrackableHtmlElement, getTrackingData } from "../utils";
import { usePathname } from "next/navigation";
import { useTrackIntersectionObserver } from "./useTrackIntersectionObserver";

const useAutoTrackClick = () => {
  useEffect(() => {
    const listener = ({ target }: MouseEvent) => {
      const trackingElement = getTrackableHtmlElement(target);
      if (trackingElement) {
        const trackingData = getTrackingData(trackingElement);
        tracker.trackEvent({ eventName: "click", ...trackingData });
      }
    };
    window.addEventListener("click", listener);
    return () => {
      window.removeEventListener("click", listener);
    };
  }, []);
};

const useTrackPageViews = () => {
  const pathname = usePathname();
  useEffect(() => {
    if (pathname) tracker.trackEvent({ eventName: "page_view" });
  }, [pathname]);
};

export const AutoTracker: FC = () => {
  const pathname = usePathname();
  useAutoTrackClick();
  useTrackPageViews();
  useTrackIntersectionObserver(pathname);
  return <></>;
};
