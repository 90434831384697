"use client";
import { Fragment, useEffect, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { getCookie, setCookie } from "@cappelendamm/cookie";
import { PiCaretUpDown } from "react-icons/pi";
import classNames from "classnames";
import { useRouter } from "next/navigation";
import { COOKIE_UNIVERSE } from "@/routing/routing";
import { Nullable } from "@/types";

export type Universe = Nullable<{
  tag: string;
  title: string;
}>;

type UniverseSelectProps = {
  universes: Universe[];
};

export function UniverseSelect({ universes }: UniverseSelectProps) {
  const [universe, setUniverse] = useState<Universe | null>();
  const [query, setQuery] = useState("");
  const { refresh } = useRouter();

  useEffect(() => {
    const universeTag = getCookie(COOKIE_UNIVERSE);
    const universe = universes.find(({ tag }) => universeTag === tag);
    if (universe) setUniverse(universe);
  }, [universes]);

  useEffect(() => {
    const universeTag = getCookie(COOKIE_UNIVERSE);
    if (universe && universe.tag !== universeTag && universe.tag) {
      setCookie({ name: COOKIE_UNIVERSE, value: universe.tag });
      refresh();
    }
  }, [universe, refresh]);

  const filteredUniverses =
    query === ""
      ? universes
      : universes.filter(({ tag, title }) => {
          return (
            title?.toLowerCase().includes(query.toLowerCase()) ||
            tag?.toLowerCase().includes(query.toLowerCase())
          );
        });

  return (
    <Combobox value={universe} onChange={setUniverse} nullable>
      <div className="relative sm:text-sm">
        <div className="relative w-full cursor-default overflow-hidden rounded-full bg-white text-left shadow-md focus:outline-hidden focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(universe: Universe) => universe.title ?? ""}
            className="h-8 border-none px-3 text-black focus:ring-0 focus-visible:outline-hidden"
            placeholder="Velg univers"
            title="Univers"
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <PiCaretUpDown
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute bottom-0 mb-1 max-h-60 w-full -translate-y-8 overflow-auto rounded-md bg-white py-1 ring-1 shadow-lg ring-black/5 focus:outline-hidden sm:text-sm">
            {filteredUniverses.map((universe) => (
              <Combobox.Option
                key={universe.tag}
                value={universe}
                className={({ active }) =>
                  classNames("flex h-8 items-center px-3 text-black", {
                    "bg-warning": active,
                  })
                }
              >
                {universe.title}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
