"use client";

import { getCookie, setCookie } from "@cappelendamm/cookie";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { EXPERIMENT_GROUP_COOKIE_NAME, ExperimentGroup } from "@/experiment";

export const ExperimentToggle = () => {
  const [state, setState] = useState("");
  useEffect(() => {
    const existing = getCookie(EXPERIMENT_GROUP_COOKIE_NAME);
    setState(existing);
  }, []);
  const { refresh } = useRouter();
  return (
    <button
      title="Eksprimentgruppe"
      className={
        "flex h-8 w-8 items-center justify-center rounded-full bg-white/75 text-black uppercase"
      }
      onClick={() => {
        setCookie({
          name: EXPERIMENT_GROUP_COOKIE_NAME,
          value:
            state === ExperimentGroup.A ? ExperimentGroup.B : ExperimentGroup.A,
        });
        setState(
          state === ExperimentGroup.A ? ExperimentGroup.B : ExperimentGroup.A,
        );
        refresh();
      }}
    >
      {state}
    </button>
  );
};
